export const extractBase64fromString = (fullbase64) => {
  if (typeof fullbase64 !== "string") return null;

  return fullbase64.replace("data:image/jpeg;base64,", "");
};

export const extractOnlyNumbersFromString = (str) => {
  if (typeof str !== "string") return null;
  return str.replace(/[^0-9]/g, "");
};

export const tryParse = (stringified) => {
  try {
    return JSON.parse(stringified);
  } catch (e) {
    if (!stringified) return [];
    return [stringified];
  }
};

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";

const defaultValue = {
  nomeDoCondominio: "",
  urlLogo: "",
  required_fields: null,
};

export const CondominioContext = createContext(defaultValue);

const reducerCondominio = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const stateCondominio = {
  nomeDoCondominio: "",
  urlLogo: "",
  required_fields: null,
};

export const CondominioProvider = ({ children }) => {
  const [{ nomeDoCondominio, urlLogo, required_fields }, dispatch] = useReducer(
    reducerCondominio,
    stateCondominio
  );

  const setCondominio = useCallback(
    (data) => {
      dispatch({
        type: "SET_DATA",
        payload: data,
      });
    },
    [dispatch]
  );

  const value = useMemo(
    () => ({
      nomeDoCondominio,
      urlLogo,
      setCondominio,
      required_fields,
    }),
    [nomeDoCondominio, setCondominio, urlLogo, required_fields]
  );

  return (
    <CondominioContext.Provider value={value}>
      {children}
    </CondominioContext.Provider>
  );
};

/**
 * Returns the value of the CondominioContext using the useContext hook.
 *
 * @return {{
 *  nomeDoCondominio: string,
 *  urlLogo: string,
 * required_fields,
 *  setCondominio: (data: { nomeDoCondominio: string, urlLogo: string }) => void
 *  }} The value of the CondominioContext.
 * @example
 * const { nomeDoCondominio, urlLogo } = useCondominio();
 */
export const useCondominio = () => {
  return useContext(CondominioContext);
};
